import React, { useEffect, useRef } from "react";

// markup
const IndexPage = () => {
    const swaggerRef = useRef(null);

    const apiUrl = `${process.env.GATSBY_API_URL}` || 'api/v1';


    useEffect(() => {
        window.SwaggerUI({
            domNode: swaggerRef.current,
            url: apiUrl + "openapi.yaml"
        });
    }, [apiUrl]);

    return <div ref={swaggerRef} id="swaggerWrapper" />;
};

export default IndexPage;